.document-table .table {
    padding-left: 0px;
    margin-bottom: 0px;
  
    width: 100%;
    border-collapse: collapse;
    table-layout: auto !important;
    border: none !important;
    margin-top:0px !important;
  }

  .document-table .table .sticky{
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
  }

  .textLeft {
    text-align:left !important;
  }
  
  .textCenter {
    text-align:center !important;
  }

  .document-table .table > tbody > tr > td {
    color: #5B6770;
    text-align: center;
    font-size: 14px;
    padding: 0px;
    min-width: 75px;
  }

  .document-table .table > tbody > tr > td.break-words{
    word-break: break-word;
    overflow-wrap: break-word;
    min-width: 90px;
  }

  .document-table .table .max-width{
    max-width: 170px;
  }
  
  .document-table .table > thead {
    padding: 0;
    border-right: 0px solid #8c959b;
    font-weight: 900;
    letter-spacing: 0.04em;  
  
  }
  
  .document-table .table > thead > tr > th {
    background: #8c959b !important;
    color: #fff !important;
    font-size: 14px;
    font-weight: bold;
    border-bottom: 0px;
    padding: 10px 0px 0px 10px;
    text-align: center;
    vertical-align: middle;
    border-top: 1px solid white; 
  }
  
  .document-table .table tbody tr:nth-child(odd) {
    background-color: #fff;
  }
  
  .document-table .table tbody tr:nth-child(even) {
    background-color: rgb(244, 244, 244);
  }

  .document-table .table > tbody > tr > td.view-file{
    color:#05A9B1;
  }

  .document-table .table > tbody > tr > td.view-file:hover{
    cursor: pointer;
    filter: brightness(1.05); 
  }

  .document-table .table .document-type{
    text-align: left;
    padding-left: 10px;
  }

  .document-table .table .description{
    text-align: left;
    padding-left: 10px;
  }

  .document-table .copied{
    color:#5B6770;
  }

.document-table .row-pagination{
  margin-top: 30px;
}

  .document-table  .pageLink:not(:disabled) {
    cursor: pointer;
}

.document-table  .pageLink:disabled {
    cursor: default;
}

.document-table  .pageLink {
    position: relative;
    display: block;
    padding: 10px 15px !important;
    margin-left: -1px;
    line-height: 1.25;
    color: #05A9B1;
    background-color: #fff;
    border: 1px solid #dee2e6;
    text-decoration: none !important;
    height: 40px;
    font-size:16px;
}

.document-table   .pageLink:hover
{
    color: #05A9B1;
}

.document-table  .pageLink:focus {
    outline: none;
}

.document-table  .page-item {
    /* position: relative;
    display: block; */
    padding: 10px 15px !important;
    margin-left: -1px;
    line-height: 1.25;
    height: 40px;
    font-size: 14px;
    text-decoration: none !important;
    color: #5B6770;
    font-family: Avenir, 'Avenir LT 55 Roman', sans-serif;
    font-style: normal;
}

.document-table  .page-item.active a {

    background-color: #05A9B1;
    border-color: #05A9B1;
    font-weight: 700;
}

.document-table  .page-item:hover {
    color: #5B6770;
}

.document-table  .active .pageLink {
    z-index: 1;
    color: #fff;
    background-color: #05A9B1;
    border-color: #05A9B1;
}

.document-table  .disabled .pageLink {
    color: #dee2e6;
    background-color: #fff;
}

.document-table  .disabled .pageLink:hover, .document-table  .disabled .pageLink:focus {
    cursor: initial;
    color: #dee2e6;
}