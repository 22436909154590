.filters{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: 
        "document-type issuer issuer"
        "wt-region iso-code identifier";
    padding-left: 10px;
    padding-right: 15px;
    grid-gap: 15px;
}

.filters .document-type{
    grid-area: document-type;
}

.filters .wt-region{
    grid-area: wt-region;
}

.filters .iso-code{
    grid-area: iso-code;
}

.filters .identifer{
    grid-area: identifier;
}

.filters .issuer{
    grid-area: issuer;
}

@media (max-width: 768px) {
    .filters{
        width: auto;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-template-areas: 
            "document-type"
            "issuer"
            "wt-region" 
            "iso-code" 
            "identifier";
        padding-right: 10px;
        padding-bottom: 20px;
    }
    
}