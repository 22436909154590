.app {
    font-family: Avenir, "Avenir LT 55 Roman", sans-serif;
    font-size: 13px;
    line-height: 150%;
    height: 100%;
    margin-bottom: 60px;
}

/* Global modal settings for the overlay (background) */
.modal-overlay{
    background-color: rgba(0, 0, 0, 0.5);
}

.app .waiting{
    display: flex;
    justify-content: center;
    margin-top: 0px;
}

.app .waiting .waiting-icon{
    color: #05A9B1;
    margin-bottom: 25px;
}

.app .download-buttons{
    display: flex;
    justify-content: space-between;
}

.download-all{
    padding-left: 15px;
}

.download-all button{
    display: inline-block;
    height: 38px;
    /* width:100%; */
    color: #fff;
    border: none;
    text-transform: uppercase;
    font: inherit;
    font-size: 14px;
    font-weight: 900;
    letter-spacing: 0.06em;
    line-height: 1.2em;
    padding: 2px 19px 0; 
    cursor: pointer;
    background-color: #05A9B1;
}

.download-all button.not-active{
    cursor: pointer;
    background: #fff;
    color: #05A9B1;
    border: 1px solid #05A9B1;
}

.download-all button:disabled{
    cursor: initial;
    background: #fff;
    color: #05A9B1;
    border: 1px solid #05A9B1;
}

.download-all button:hover {
    filter: brightness(1.05); 
}

.app .filter-row{
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}

@media (max-width: 768px) {

    .app .filter-row{
        flex-direction: column;
    }

    .app .download-buttons{
        justify-content: flex-end;
    }
}